<template>
  <div class="ReviewApprove">
    <!-- head -->
    <div class="head">
      <div class="application_top">
        <div
          v-for="(item, itemIndex) in auditStsTab"
          :key="item.key"
          :class="['top_item', query.key === item.key ? 'act' : '']"
          @click="handleChangeTabs(item, itemIndex)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="close-btn">
        <span
          v-if="creativeAuditSts === 'A' && role !== 'SUPER_ADMIN'"
          style="margin-right: 10px; line-height: 32px"
        >审核权限</span
        >
        <a-popconfirm
          title="确认关闭审核权限?"
          ok-text="是"
          cancel-text="否"
          v-if="creativeAuditSts === 'A' && role !== 'SUPER_ADMIN'"
          @confirm="handleChangeAuditSTS"
        >
          <a-switch :checked="creativeAuditSts === 'A'" />
        </a-popconfirm>
      </div>
    </div>
    <!-- search -->
    <a-form-model :model="viewData" class="ReviewSerachForm">
      <a-select
        v-if="role === 'SUPER_ADMIN' || (adminUserId != null && adminUserId == 1)"
        style="width: 180px"
        class="m-r-10 m-b-20"
        :allowClear="false"
        v-model="viewData.userId"
        placeholder="请选择公司"
      >
        <a-select-option v-for="item in companyList" :key="item.id" :value="item.id">
          {{ item.name }}
        </a-select-option>
      </a-select>
      <m-select-more
        v-if="showPlat === '1' && query.key !== '3'"
        class="m-r-10 m-b-20"
        v-model="viewData.platIdList"
        :allData="platList"
        :searchById="true"
        :hasIcon="true"
        style="width: 180px"
        :showId="false"
        :showLabel="false"
        label="广告平台"
      />
      <m-select-more
        v-if="query.key !== '3'"
        class="m-r-10 m-b-20"
        v-model="viewData.positionIdList"
        :allData="PositionList"
        :searchById="true"
        :hasIcon="true"
        style="width: 180px"
        :showId="false"
        :showLabel="false"
        label="广告样式"
      />
      <a-select
        v-if="query.key !== '3'"
        class="m-r-10 m-b-20"
        showSearch
        style="width: 180px"
        allowClear
        v-model="viewData.type"
        placeholder="请选择素材类型"
      >
        <a-select-option v-for="pitem in demoTypeOptions" :key="pitem.id" :value="pitem.value">
          {{ pitem.label }}
        </a-select-option>
      </a-select>
      <a-select
        v-if="query.key === '1'"
        class="m-r-10 m-b-20"
        showSearch
        style="width: 180px"
        allowClear
        v-model="viewData.auditSts"
        placeholder="请选择审核状态"
      >
        <a-select-option v-for="pitem in stsOptions" :key="pitem.id" :value="pitem.value">
          {{ pitem.label }}
        </a-select-option>
      </a-select>
      <m-select-more
        class="m-r-10 m-b-20"
        style="width: 220px"
        v-model="viewData.auditUsers"
        :allData="auditUserOptions"
        :searchById="false"
        :hasIcon="false"
        :showId="false"
        width="450px"
        label="审核人员"
      />
      <div v-if="+query.key !== 3" style="display: flex; width: 315px" class="dateBox m-r-20 m-b-20">
        <span style="display: inline-block; width: 75px">{{ query.key == '2' ? '屏蔽时间' : '展示时间' }} </span>
        <a-range-picker
          style="width: 100%"
          valueFormat="YYYY-MM-DD"
          :disabledDate="disabledDate"
          :value="rangePickerDate"
          @change="handleChangeTime"
          :placeholder="['Start', 'End']"
        >
        </a-range-picker>
      </div>
      <div v-if="+query.key === 3" style="display: flex; width: 230px" class="dateBox m-r-20 m-b-20">
        <a-range-picker
          style="width: 100%"
          format="YYYY-MM-DD"
          :disabledDate="disabledDate"
          :value="rangePickerDate"
          @change="handleChangeTime"
          :ranges="{
            今日: [initDate(0, 0).start, initDate(0, 0).end],
            昨日: [initDate(1, 1).start, initDate(1, 1).end],
            最近一周: [initDate(6, 0).start, initDate(6, 0).end],
            最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
          }"
          :placeholder="['Start', 'End']"
        >
        </a-range-picker>
      </div>
      <m-select-more
        class="m-r-10 m-b-20"
        style="width: 220px"
        v-if="query.key === '1'"
        v-model="viewData.placeIdList"
        placeholder="请选择广告位"
        :allData="placeList"
        :searchById="false"
        :hasIcon="true"
        :showId="true"
        width="450px"
        label="广告位"
      />
      <a-input
        v-if="query.key !== '3'"
        class="m-r-10"
        style="width: 180px"
        allowClear
        v-model.trim="viewData.keyWords"
        placeholder="标题、描述、标签"
      >
      </a-input>
      <a-button type="primary" @click="handleSerach">查询</a-button>
    </a-form-model>
    <!-- table list -->
    <template v-if="+query.key !== 3">
      <a-spin :spinning="spinLoading" class="ReviewContent">
        <div v-if="tableData && tableData.length > 0" class="tableDataBox">
          <a-row type="flex" :gutter="20">
            <a-col
              v-for="(item, index) in tableData"
              :key="item.id + index"
              class="imgItemBox"
              style="margin-bottom: 25px; width: 230px; height: 15%"
            >
              <a-card
                class="items"
                style="width: 100%; position: relative; height: 100%"
                :bodyStyle="{ padding: '15px 6px' }"
              >
                <!-- 事件只在点击图片和背景时候选中 -->
                <div
                  slot="cover"
                  v-if="item.type == 1"
                  class="position-r imageCoverStyle"
                  @click="CheckTableItem(item)"
                >
                  <el-image
                    style="width: 100%"
                    alt="加载失败"
                    object-fil="cover"
                    :src="item.creativeUrl"
                    :ref="'previewImg' + index"
                  >
                  </el-image>
                  <div class="operationBtn">
                    <span
                      class="custBtnSpan"
                      @click.stop="(e) => handleChangeAudit(e, 'batch-item-detail', item)"
                    >详情</span
                    >
                    <span
                      class="custBtnSpan"
                      v-if="['2'].includes(query.key)"
                      @click.stop="(e) => handleChangeAudit(e, 'batch-item-success', item)"
                    >通过</span
                    >
                    <span
                      class="custBtnSpan"
                      v-if="['1'].includes(query.key)"
                      @click.stop="(e) => handleChangeAudit(e, 'batch-item-refuse', item)"
                    >拒绝</span
                    >
                  </div>
                </div>
                <div slot="cover" v-if="item.type == 2" class="position-r imageCoverStyle">
                  <VideoPreview :videoList="[item]"></VideoPreview>
                  <div class="operationBtn">
                    <span
                      class="custBtnSpan"
                      @click="(e) => handleChangeAudit(e, 'batch-item-detail', item)"
                    >详情</span
                    >
                    <span
                      class="custBtnSpan"
                      v-if="['2'].includes(query.key)"
                      @click="(e) => handleChangeAudit(e, 'batch-item-success', item)"
                    >通过</span
                    >
                    <span
                      class="custBtnSpan"
                      v-if="['1'].includes(query.key)"
                      @click="(e) => handleChangeAudit(e, 'batch-item-refuse', item)"
                    >拒绝</span
                    >
                  </div>
                </div>
                <div class="imgfullscreen">
                  <!-- 图片只在放大按钮后可以预览 -->
                  <a-icon type="fullscreen" @click="handleFullscreen(item, index)" />
                  <!-- 图片覆盖掉icon，相当于点击图片 -->
                  <el-image
                    v-if="+item.type === 1"
                    object-fil="cover"
                    :src="item.creativeUrl"
                    :ref="'previewImg' + index"
                    :preview-src-list="[item.creativeUrl]"
                  >
                  </el-image>
                </div>
                <a-checkbox class="imgCheckbox" v-model="item.isChecked" @change="handleCheckTableItem"></a-checkbox>
                <a-card-meta>
                  <template slot="description">
                    <div style="font-size: 12px">
                      <span class="grayWord">{{ showText(item) }}</span>
                    </div>
                    <a
                      v-if="item.downloadUrl || item.landingPage"
                      target="_blank"
                      :href="item.downloadUrl || item.landingPage || '#'"
                      class="text-ellipsis m-t-10"
                    >
                      <a-icon type="link" />
                      {{ item.downloadUrl || item.landingPage }}
                    </a>
                    <a v-else class="text-ellipsis m-t-10">
                      <a-icon type="link" />
                      {{ '--' }}
                    </a>
                  </template>
                </a-card-meta>
              </a-card>
            </a-col>
          </a-row>
          <!-- 分页 -->
          <a-pagination
            class="pagination"
            show-size-changer
            :current="query.page"
            :total="total"
            :show-total="(total) => `共 ${total} 条`"
            @change="handleCurrentPage"
            @showSizeChange="handleChangePageSize"
            :page-size-options="['30', '60', '100', '300']"
            :pageSize="query.limit"
          />
        </div>
        <m-empty v-if="tableData && tableData.length == 0" style="height: 40vh">
          <template #description>暂无数据</template>
        </m-empty>
      </a-spin>
      <div class="m-b-10 fixedButton">
        <div class="fixed-content">
          <a-checkbox
            v-model="allChecked"
            class="m-r-20"
            style="margin-right: 47px"
            @change="handleCheckAll"
          >全选</a-checkbox
          >
          <span class="m-r-20 fixed-word">{{ query.key == '1' ? '已展示' : '已屏蔽' }}素材共有{{ total }}张</span>
          <a-button
            :type="!(tableData?.filter((item) => item.isChecked == true).length !== 0) ? '' : 'primary'"
            v-if="query.key === '2'"
            :disabled="!(tableData?.filter((item) => item.isChecked == true).length !== 0)"
            class="m-r-10"
            @click="(e) => handleChangeAudit(e, 'batch-success')"
          >批量通过</a-button
          >
          <a-button
            :type="!(tableData?.filter((item) => item.isChecked == true).length !== 0) ? '' : 'primary'"
            :disabled="!(tableData?.filter((item) => item.isChecked == true).length !== 0)"
            class="m-r-10"
            v-if="query.key === '1'"
            @click="(e) => handleChangeAudit(e, 'batch-refuse')"
          >批量屏蔽</a-button
          >
        </div>
      </div>
    </template>
    <template v-if="+query.key === 3">
      <ReviewTable :arr="viewData" ref="table"></ReviewTable>
    </template>
    <!-- 详情的弹框 -->
    <a-modal
      class="detailModal"
      title="素材详情"
      :visible="detailModalVisible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      :width="1000"
      @cancel="handleCancel"
    >
      <div style="display: flex">
        <div class="flex-column-center" style="flex: 1">
          <img
            v-if="detailForm.type == 1"
            alt="加载失败"
            style="width: 100%"
            object-fil="cover"
            :src="detailForm.creativeUrl"
          />
          <video
            v-if="detailForm.type == 2"
            width="100%"
            height="100%"
            object-fit="fill"
            controls="false"
            disablePictureInPicture="true"
            noremote
            :autoplay="false"
            :loop="true"
            footbar
            nofullscreen
            noremoteplayback
            noplaybackrate
            controlslist="nodownload"
            name="media"
          >
            <source :src="detailForm.creativeUrl" type="video/mp4" />
          </video>
        </div>
        <div style="flex: 2">
          <a-form-model :form="detailForm" v-bind="layout">
            <a-form-model-item label="素材ID" class="m-b-0">
              <span>{{ detailForm.id || '--' }}</span>
            </a-form-model-item>
            <a-form-model-item v-if="showPlat === '1'" label="广告平台" class="m-b-0">
              <span>{{ detailForm.platName || '--' }}</span>
            </a-form-model-item>
            <a-form-model-item label="素材标题" class="m-b-0">
              <span class="text-ellipsis-1">{{ detailForm.title || '--' }}</span>
            </a-form-model-item>
            <a-form-model-item label="素材描述" class="m-b-0">
              <span class="text-ellipsis-1">{{ detailForm.description || '--' }}</span>
            </a-form-model-item>
            <a-form-model-item label="下载地址" class="m-b-0 text-ellipsis-1">
              <a target="_blank" class="text-ellipsis-1" :href="detailForm.downloadUrl">{{
                detailForm.downloadUrl || '--'
              }}</a>
            </a-form-model-item>
            <a-form-model-item label="落地页" class="m-b-0">
              <a target="_blank" :href="detailForm.landingPage" class="text-ellipsis-1">{{
                detailForm.landingPage || '--'
              }}</a>
            </a-form-model-item>
            <a-form-model-item label="唤醒地址" class="m-b-0">
              <a target="_blank" :href="detailForm.deeplinkUrl" class="text-ellipsis-1">{{
                detailForm.deeplinkUrl || '--'
              }}</a>
            </a-form-model-item>
            <a-form-model-item v-if="query.key == '2'" label="屏蔽时间" class="m-b-0">
              <span class="text-ellipsis-1">{{ detailForm.auditTime?.replace('T', ' ') || '--' }}</span>
            </a-form-model-item>
            <a-form-model-item v-else label="展示时间" class="m-b-0">
              <span class="text-ellipsis-1">{{ detailForm.impressTime?.replace('T', ' ') || '--' }}</span>
            </a-form-model-item>
            <a-form-model-item label="标签" class="m-b-0">
              <div class="labelBoxStyle">
                <a-textarea v-model="inputValue" placeholder="多个关键词以换行或逗号分隔" :rows="6" />
                <div class="add-btn">
                  <a-button type="primary" @click="handleInputConfirm">>></a-button>
                </div>
                <div class="tag-box">
                  <!-- @input=板块-->
                  <a-input v-model.trim="searchWord" placeholder="请输入搜索字段" />
                  <a-tag
                    :key="titem + tindex"
                    class="flexStyle text-ellipsis"
                    v-for="(titem, tindex) in showtags"
                    closable
                    @close="() => handleCloseTag(titem)"
                  >
                    <span class="text-ellipsis">{{ titem }}</span>
                  </a-tag>
                </div>
              </div>
            </a-form-model-item>
            <a-form-model-item label="审核人" class="m-b-0">
              <span class="text-ellipsis-1">{{ detailForm.auditUsername || '--' }}</span>
            </a-form-model-item>
            <a-form-model-item label="审核" class="m-b-0">
              <a-radio-group v-model="detailForm.auditSts">
                <a-radio v-if="query.key === '2'" value="1">通过</a-radio>
                <a-radio v-if="query.key === '1'" value="2">拒绝</a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </a-modal>
    <!-- 图片弹窗 -->
    <imgModel :visible="imgModelVisible" :data="imgModelData" @close="handleCloseImgModel"></imgModel>
  </div>
</template>
<script>
import { closeAudit } from '@/api/creative'
import mixGetList from '@/mixins/getList'
import { platformLinkList } from '@/api/aggregate'
import { listByCreative, showedPager, auditShowed, updateTagsShowed } from '@/api/review'
import { getPositionList, getAuditUserList } from '@/api/common'
import { mapGetters, mapState } from 'vuex'
import mixDate from '@/mixins/initDate'
import VideoPreview from '@/components/VideoPreview'
import imgModel from '../imgModel'
import moment from 'moment'
import ReviewTable from './ReviewTable'
export default {
  name: 'BeforeReview',
  mixins: [mixDate, mixGetList],
  components: { VideoPreview, imgModel, ReviewTable },
  data () {
    return {
      searchWord: '',
      // 传入参数
      viewData: {
        platIdList: undefined,
        positionIdList: undefined,
        keyWords: undefined,
        auditSts: undefined,
        userId: undefined,
        auditUsers: []
      },
      // 默认已显示
      query: {
        page: 1,
        limit: 30,
        key: '1'
      },
      // 全选
      allChecked: false,
      confirmLoading: false,
      isLoading: false,
      detailModalVisible: false,
      visible: false,
      layout: {
        labelCol: { span: 3 },
        wrapperCol: { span: 18 }
      },
      total: 0,
      PositionList: [],
      tableQuery: undefined,
      auditUserOptions: [],
      companyList: [],
      tableList: [],
      demoTypeOptions: [
        {
          label: '图片',
          value: 1
        },
        {
          label: '视频',
          value: 2
        }
      ],
      stsOptions: [
        {
          label: '未审核',
          value: 0
        },
        {
          label: '通过',
          value: 1
        }
      ],
      tableData: undefined,
      SUPER_ADMIN: false,
      inputValue: '',
      // 标签
      tags: [],
      allTags: [],
      // 弹窗
      detailForm: {},
      rangePickerDate: [],
      spinLoading: false,
      // 图片弹窗
      imgModelVisible: false,
      imgModelData: {},
      auditStsTab: [
        { name: '已展示', key: '1' },
        { name: '已屏蔽', key: '2' },
        { name: '审核报表', key: '3' }
      ]
    }
  },
  computed: {
    ...mapState({
      adminUserId: (state) => state.user.adminUserId,
      showPlat: (state) => state.user.showPlat,
      role: (state) => state.user.roles[0],
      creativeAuditSts: (state) => state.user.creativeAuditSts
    }),
    ...mapGetters(['adplanResourceUrl']),
    showtags () {
      const arr = []
      this.tags.forEach((item, index) => {
        if (item.includes(this.searchWord)) {
          arr.push(item)
        }
      })
      return arr
    }
  },
  async created () {
    // 广告样式的下拉
    this.getPositionListFn()
    // 审核人员下拉
    this.getAuditUserListFn()
    // 广告位
    this.getPlaceList()
    // 广告平台
    this.getPlatList()
    this.initTime()
    // 判断是否是超级管理员 || 超管创建的子账号 显示
    this.getIsAdmin() && (await this.companCreative())
    await this.initPage()
  },
  methods: {
    async handleChangeAuditSTS () {
      const resp = await closeAudit()
      if (resp.code === 200) {
        this.$store.dispatch('changeCreativeAuditSts', 'S')
        this.$router.push({
          path: '/accountInfo'
        })
      }
    },
    async getPlatList () {
      const resp = await platformLinkList()
      if (resp.code === 200) {
        this.platList = resp.data || []
        // 只显示APi 的广告平台
        this.platList = this.platList.filter((item) => item.accessType === '1')
        // 先投后审不要直客
        this.platList = this.platList.filter((item) => item.id !== 1019)
      }
    },
    // 页面初始化
    initPage () {
      this.showedPagerFn()
      document.documentElement.scrollTop = 0
    },
    // 判断是否是超管及创建的子账户
    getIsAdmin () {
      return this.role === 'SUPER_ADMIN' || (this.adminUserId != null && this.adminUserId === 1)
    },
    // 时间初始化
    initTime () {
      const key = +this.query.key
      const { endDate, startDate } = this.initDate(key === 2 || key === 3 ? 6 : 0, 0)
      this.rangePickerDate = [startDate, endDate]
      this.viewData = {
        ...this.viewData,
        beginDate: startDate,
        endDate: endDate
      }
    },
    // 不可选时间
    disabledDate (current) {
      // 获取当前日期
      const today = moment()
      // 获取七天前的日期
      const sevenDaysAgo = moment().subtract(7, 'days')
      if (this.query.key === '2' || this.query.key === '3') {
        // 如果当前日期在未来，则禁用
        return current.isAfter(today)
      } else {
        // 如果当前日期在七天前之前或七天后之后，则禁用
        return current.isBefore(sevenDaysAgo) || current.isSameOrAfter(today)
      }
    },
    // 改变时间
    handleChangeTime (date, dateString) {
      this.rangePickerDate = [dateString[0], dateString[1]]
      this.viewData = {
        ...this.viewData,
        beginDate: dateString[0],
        endDate: dateString[1]
      }
    },
    // 切换tab
    async handleChangeTabs (data) {
      this.query.key = data.key
      data.key === '2' &&
        (this.viewData = {
          ...this.viewData,
          placeIdList: []
        })
      this.viewData.auditSts = data.key === '2' ? '2' : undefined
      // 处理时间
      await this.initTime()
      if (+this.query.key === 3) {
        this.$refs.table.getTable()
      } else {
        await this.initPage()
      }
    },
    // 公司下拉
    async companCreative () {
      const res = await listByCreative()
      if (res.code === 200 && res.data) {
        const tempData = res.data
        this.viewData.userId = res.data[0] ? res.data[0].id : undefined
        this.companyList = tempData
      }
    },
    // 广告样式的下拉
    async getPositionListFn (data) {
      const res = await getPositionList(data)
      const tempData = res.data
      const newData = tempData.map((item) => {
        item.label = item.name
        item.value = item.id
        return item
      })
      this.PositionList = newData
    },
    // 审核人员下拉
    async getAuditUserListFn () {
      const res = await getAuditUserList()
      if (res.code === 200 && res.data) {
        const tempData = res.data
        const newData = tempData.map((item) => {
          item.name = item.username
          return item
        })
        this.auditUserOptions = newData
      }
    },
    // 搜索
    handleSerach () {
      this.query.page = 1
      if (+this.query.key === 3) {
        this.$refs.table.getTable()
      } else {
        this.showedPagerFn()
      }
    },
    // 全选
    handleCheckAll (e) {
      this.tableData = this.tableData.map((item) => {
        item.isChecked = this.allChecked
        return item
      })
    },
    // 获取先投后审-列表
    async showedPagerFn () {
      const query = {
        ...this.viewData,
        page: this.query.page,
        limit: this.query.limit
      }
      const res = await showedPager(query)
      if (res.code === 200 && res.data) {
        const tempData = res.data.items || []
        const newData = tempData.map((item) => {
          item.isChecked = false
          if (item.platName === '直客投放') {
            item.creativeUrl = this.adplanResourceUrl + item.creativeUrl
          }
          item.url = item.creativeUrl
          item.poster = item.creativeUrl
          return item
        })
        this.tableData = newData
      }
      this.total = (res.data && res.data.total) || 0
    },
    // 先投后审-审核
    handleChangeAudit (e, temptype, item) {
      let tempData = []
      let newData = []
      if (item) {
        const {
          id: creativeId,
          description,
          title,
          landingPage,
          auditTime,
          impressTime,
          downloadUrl,
          auditSts,
          tags,
          creativeUrl,
          type,
          platName,
          auditUsername
        } = item
        const { userId } = this.viewData
        switch (temptype) {
          // 单个通过
          case 'batch-item-success':
            if (this.getIsAdmin()) {
              this.auditFn({ userId: userId.toString(), auditVos: [{ creativeId, auditSts: '1' }] })
            } else {
              this.auditFn({ auditVos: [{ creativeId, auditSts: '1' }] })
            }
            break
          // 单个拒绝
          case 'batch-item-refuse':
            if (this.getIsAdmin()) {
              this.auditFn({ userId: userId.toString(), auditVos: [{ creativeId, auditSts: '2' }] })
            } else {
              this.auditFn({ auditVos: [{ creativeId, auditSts: '2' }] })
            }
            break
          // 细节
          case 'batch-item-detail':
            this.detailModalVisible = true
            this.detailForm = {
              auditTime,
              impressTime,
              id: creativeId,
              platName,
              description,
              title,
              downloadUrl,
              landingPage,
              auditUsername,
              tags: tags && tags.length > 0 && tags.split(','),
              auditSts,
              type,
              creativeUrl
            }
            if (tags && tags.length > 0) {
              this.tags = tags.split(',')
            }
            break
          default:
            break
        }
      } else {
        const { userId } = this.viewData
        switch (temptype) {
          case 'batch-success':
            tempData = this.tableData.filter((item) => item.isChecked && item)
            if (tempData.length === 0) return
            newData = tempData.map((ele) => {
              return { creativeId: ele.id, auditSts: '1' }
            })
            if (this.getIsAdmin()) {
              this.auditFn({ userId: userId.toString(), auditVos: newData })
            } else {
              this.auditFn({ auditVos: newData })
            }
            break
          case 'batch-refuse':
            tempData = this.tableData.filter((item) => item.isChecked && item)
            newData = tempData.map((ele) => {
              return { creativeId: ele.id, auditSts: '2' }
            })
            if (this.getIsAdmin()) {
              this.auditFn({ userId: userId.toString(), auditVos: newData })
            } else {
              this.auditFn({ auditVos: newData })
            }
            break
          default:
            break
        }
      }
    },
    showText (item) {
      if (item.title) {
        return item.title
      } else if (item.description) {
        return item.description
      } else {
        return '--'
      }
    },
    // icon全屏幕
    handleFullscreen (item, index) {
      if (item.type === 1 && this.$refs['previewImg' + index]) {
        this.$refs['previewImg' + index][0].showViewer = true
        document.body.style.overflow = 'hidden'
      } else {
        this.imgModelVisible = true
        this.imgModelData = { type: item.type, url: item.creativeUrl }
      }
    },
    // 单个checkbox
    handleCheckTableItem (e) {
      this.allChecked = this.tableData.every((item) => item.isChecked)
    },
    // 点图片也选中
    CheckTableItem (a) {
      a.isChecked = !a.isChecked
      this.allChecked = this.tableData.every((item) => item.isChecked)
    },
    // 确认弹框
    async handleOk () {
      const { id: creativeId, auditSts } = this.detailForm
      const { userId } = this.viewData
      // 关于标签
      const tags = this.allTags.map((x) => x).join(',')
      const res = await updateTagsShowed({
        id: creativeId,
        tags: tags
      })
      if (res.code === 200) {
        if (this.getIsAdmin()) {
          this.auditFn({ userId: userId.toString(), auditVos: [{ creativeId, auditSts }] })
        } else {
          this.auditFn({ auditVos: [{ creativeId, auditSts }] })
        }
        this.detailModalVisible = false
        this.initPage()
      }
    },
    // 通过审核 拒绝审核
    async auditFn (data) {
      const res = await auditShowed(data)
      if (res.code === 200) {
        this.initPage()
        // 操作弹框内容
        Object.assign(this, {
          tags: [],
          detailForm: {},
          detailModalVisible: false,
          inputValue: '',
          searchWord: ''
        })
      }
    },
    // 取消弹框
    handleCancel () {
      Object.assign(this, {
        tags: [],
        allTags: [],
        detailForm: { creativeUrl: '' },
        detailModalVisible: false,
        inputValue: '',
        searchWord: ''
      })
    },
    // input 确认添加
    handleInputConfirm () {
      const inputValue = this.inputValue
        .replace(/,|，|[\r\n]/g, ',')
        .split(',')
        .filter((item) => item && item.trim())
      let tags = this.tags
      if (inputValue && inputValue.length !== 0) {
        tags = [...tags, ...inputValue]
      }
      Object.assign(this, {
        tags,
        allTags: tags,
        inputValue: ''
      })
    },
    // 关闭tag
    handleCloseTag (tagItme) {
      this.tags = this.tags.filter((item) => item !== tagItme)
      this.allTags = this.tags
    },
    handleCloseImgModel () {
      this.imgModelVisible = false
      this.imgModelData = {}
    },
    // 分页
    handleCurrentPage (page) {
      this.query.page = page
      this.showedPagerFn()
    },
    handleChangePageSize (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.showedPagerFn()
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/mixins/redefineAnt.less';
.m-t-10 {
  margin-top: 10px;
}
.grayWord {
  height: 38px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
  -webkit-box-orient: vertical; /* 垂直排列 */
  word-break: break-all;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-b-0 {
  margin-bottom: 0px;
}
.m-r-10 {
  margin-right: 10px;
}
.m-r-20 {
  margin-right: 20px;
}
.position-r {
  position: relative;
}
.custBtnSpan {
  color: @primary-color;
  cursor: pointer;
  padding: 0 15px;
}
.fixedButton {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: -10px;
  left: 1px;
  background-color: #fff;
  height: 60px;
  width: 100%;
  z-index: 1000;
  box-shadow: 4px 0 10px #0000000a;
  .fixed-content {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 24px;
    .fixed-word {
      font-size: 14px;
      font-weight: 400;
      color: #000000d9;
      line-height: 20px;
    }
  }
}
.font-title {
  font-size: 16px;
  font-weight: 700;
  color: #3d3a3d;
  margin-right: 47px;
}
.text-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
// 控制元素显示
.imgItemBox:hover .operationBtn {
  display: block;
}
.imageCoverStyle {
  height: 340px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #e7e7e7;
}
.operationBtn {
  position: absolute;
  background: #f5f5f5;
  width: 100%;
  display: none;
  bottom: 0;
  height: 40px;
  line-height: 40px;
  text-align: center;
  padding: 0 15px;
  z-index: 999;
}

.detailModal {
  .text-ellipsis {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .text-ellipsis-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* 限制在一个块元素显示的文本的行数 */
    -webkit-box-orient: vertical; /* 垂直排列 */
    word-break: break-all;
  }
  .tag-box {
    border: 1px solid #d9d9d9;
    width: 100%;
    height: 240px;
    border-radius: 2px;
    overflow-y: scroll;
    padding: 10px;
    .ant-tag {
      display: block;
      margin-bottom: 10px;
      margin-right: 0px;
      display: flex;
      justify-content: space-between;
      justify-items: center;
      i.anticon-close {
        margin-top: 4px;
      }
    }
  }
  .pagination {
    margin-bottom: 20px;
  }
  .flex-column-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .m-b-0 {
      margin-bottom: 0px;
    }
    .flexStyle {
      display: flex !important;
    }
  }
  .labelBoxStyle {
    display: flex;
    margin-right: 25px;
    justify-content: center;
    justify-items: center;
  }
  .add-btn {
    margin: 0px 10px;
    line-height: 18;
    .ant-btn-primary {
      padding: 5px 10px;
    }
  }
}
.ReviewApprove {
  padding: 0 10px 10px 10px;
  border-radius: 5px;
  height: 100%;
  .head {
    display: flex;
    background-color: #fff;
    border-radius: 5px 5px 0 0;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e6e6e6;
    .application_top {
      display: flex;
      flex-direction: row;
      .top_item {
        font-size: 14px;
        color: #333333;
        margin: 8px 8px 0;
        padding: 0 14px;
        border-radius: 5px;
        height: 30px;
        line-height: 30px;
        cursor: pointer;
      }
      .act {
        color: @primary-color;
        background: fade(@primary-color, 10%);
      }
    }
    // .close-btn {
    // }
  }
  .tableDataBox {
    padding: 20px;
    background: #fff;
    border-radius: 5px;
  }
  .ReviewSerachForm {
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
    background: #fff;
    padding: 10px 20px;
    border-radius: 0 0 5px 5px;
    margin-bottom: 20px;
    .dateBox {
      height: 36px;
      line-height: 36px;
      border-radius: 5px;
      padding-left: 10px;
      border: 1px solid #d9d9d9;
      .ant-input {
        outline: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        &:focus {
          outline: none;
        }
      }
    }
  }
  .ReviewContent {
    border-radius: 5px;
    margin-bottom: 50px;
    .imgCheckbox {
      position: absolute;
      top: 5px;
      right: 10px;
    }
    .imgfullscreen {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 5px;
      left: 10px;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      .el-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        /*覆盖掉icon*/
        z-index: 300;
      }
    }
    ::v-deep .ant-tabs-ink-bar {
      width: 40px !important;
      left: 15px;
      border-bottom: 3px solid @primary-color;
    }
    .items {
      video::-webkit-media-controls {
        display: none !important;
      }
      video::-webkit-media-controls-timeline {
        display: none !important;
      }
      video::-webkit-media-controls-current-time-display {
        display: none !important;
      }
      video::-webkit-media-controls-time-remaining-display {
        display: none;
      }
      video::-webkit-media-controls-toggle-closed-captions-button {
        display: none;
      }
      video::-webkit-media-controls-toggle-closed-captions-button {
        display: none;
      }
      video::-webkit-media-controls-volume-slider {
        display: none;
      }
      video::-webkit-media-controls-enclosure {
        overflow: hidden;
      }
      video::-webkit-media-controls-fullscreen-button {
        display: none; //更改是否显示全屏按钮
      }
      video::-webkit-media-controls-mute-button {
        display: none;
      }
      ::v-deep .ant-checkbox-wrapper:hover {
        border: none;
      }
      ::v-deep .ant-checkbox:hover {
        border: none;
      }
      ::v-deep .ant-checkbox-inner {
        background: #536a6e !important;
        border: 1px solid #8ba2a7;
        :hover {
          border: none;
        }
      }
    }
  }
  .ant-empty {
    margin: 0;
    border-radius: 5px;
  }
  ::v-deep.ant-form.ant-form-horizontal .ant-row.ant-form-item .ant-col.ant-form-item-label {
    margin-right: 0 !important;
  }
  ::v-deep {
    .ant-calendar-picker .ant-calendar-picker-input.ant-input {
      border: none;
      height: 32px;
      line-height: 36px;
      padding: 0;
      outline: none !important;
    }
    .ant-input-affix-wrapper .ant-input-suffix {
      top: 35%;
    }
    .ant-radio {
      display: none;
    }
  }
}
::v-deep {
  .ant-modal-body {
    .batchModel {
      padding: 30px;
      display: flex;
      align-items: center;
      .icon {
        color: #fa8b15;
        font-size: 22px;
        margin-right: 20px;
      }
      p {
        margin-bottom: 0;
        line-height: 24px;
      }
    }
  }
}
</style>
